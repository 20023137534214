<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="审核状态" prop="faultStatus">
        <a-radio-group v-model="form.faultStatus" button-style="solid">
          <a-radio-button v-for="(d, index) in [{label: '同意',value: 1},{label: '拒绝',value: 2}]" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否生成退款订单" prop="sfRefundOrder">
        <a-radio-group v-model="form.sfRefundOrder" button-style="solid">
          <a-radio-button v-for="(d, index) in [{label: '是',value: 1},{label: '否',value: 0}]" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="订单号" prop="orderNo" v-if="form.sfRefundOrder == 1">
        <a-input v-model="form.orderNo" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="退款金额" prop="refundAmount" v-if="form.sfRefundOrder == 1">
        <a-input v-model="form.refundAmount" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="处理说明" prop="rejectCause">
        <a-input v-model="form.rejectCause" placeholder="请输入"/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            确定处理
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { faultInfo, faultAdd } from '@/api/pages/fault'

export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单
      form: {
        id: 0,
        faultStatus: 1,
        orderNo: '',
        refundAmount: 0,
        rejectCause: '',
        sfRefundOrder: 0
      },
      open: false,
      rules: {
        faultStatus: [{ required: true, message: '请选择故障状态', trigger: 'blur' }],
        sfRefundOrder: [{ required: true, message: '请选择是否生成退款订单', trigger: 'blur' }],
        orderNo: [{ required: true, message: '订单号不能为空', trigger: 'blur' }],
        refundAmount: [{ required: true, message: '退款金额不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    change(value, dateString) {
      console.log(value, dateString)
      this.form.publishTime = dateString
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: 0,
        faultStatus: 1,
        orderNo: '',
        refundAmount: 0,
        rejectCause: '',
        sfRefundOrder: 0
      }
    },
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      faultInfo({ id: configId }).then(response => {
        response.data.faultStatus = 1
        this.form = response.data
        this.open = true
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          faultAdd(this.form).then(response => {
            this.$message.success(
              '新增成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
